import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Col, Empty, Input, Row } from 'antd';
import { useParams } from 'react-router';
import { usePrevious } from 'react-use';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { Action, ExecuteAction } from '../../models/Action';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { PersonType } from '../../models/enums/PersonType';
import { Spinner } from '../UI/Spinner/Spinner';
import { executeAction } from '../../store/Actions/Actions.redux';
import { Visitor } from '../../models/Visits/Visitor';
import { ActionIcon } from '../Actions/ActionIcon';

interface ActionsCardProps {
  actionsData: Action[];
  pendingAction: boolean;
  visitor: Visitor | null;
  fetchVisitor: (params: any) => Promise<void>;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
  width: 200px;
`;

export const ActionsCard = ({ actionsData, pendingAction, visitor, fetchVisitor }: ActionsCardProps) => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(({ actions }) => actions?.error ?? false);
  const updating = useAppSelector(({ actions }) => actions?.updating ?? false);
  const prevUpdating = usePrevious(updating);
  const { id } = useParams();

  useEffect(() => {
    // Fetch after updating
    if (visitor && prevUpdating === true && updating === false && !error) {
      fetchVisitor({ id: visitor.Id });
    }
  }, [prevUpdating, updating, error, fetchVisitor, visitor]);

  const executeActionBtnClick = useCallback(
    (actionId: string) => {
      const data: ExecuteAction = {
        ActionId: actionId,
        PersonId: id,
        PersonType: PersonType.Visitor,
      };
      dispatch(executeAction(data));
    },
    [id, dispatch]
  );

  return (
    <Widget title={<Translated id="app.action" />}>
      {actionsData.length !== 0 && !!visitor ? (
        <div>
          <Spinner spinning={updating}>
            <div className="ant-collapse-header">
              {actionsData.map((action) => (
                <div key={action.Id} className="row">
                  <Input name="Id" hidden />
                  <Row>
                    <Col xs={3}>
                      <ActionIcon statuses={visitor.ActionStatuses} action={action} />
                    </Col>
                    <Col xs={9}>
                      <div className="gx-mb-0 gx-text-grey gx-fs-sm">
                        {pendingAction ? <ButtonSkeleton width={120} height={10} /> : action.Name}
                      </div>
                      <div className="gx-mb-0 gx-mr-3">
                        <StyledButton type="primary" onClick={() => executeActionBtnClick(action.Id)}>
                          <span className="gx-mb-0 gx-text-white gx-fs-sm">
                            {pendingAction ? <ButtonSkeleton width={120} height={10} /> : action.DisplayLabel}
                          </span>
                        </StyledButton>
                      </div>
                    </Col>
                  </Row>

                  <hr />
                </div>
              ))}
            </div>
          </Spinner>
        </div>
      ) : (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="actions.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      )}
    </Widget>
  );
};
