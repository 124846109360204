import React, { useCallback, useMemo } from 'react';

// Types
import { Modal } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { FormOptions, UseFormProps } from '../../types/Table';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import { createVisit, updateVisit } from '../../store/Reservations/Reservations.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Visit } from '../../models/Visits/Visit';
import { VisitForm } from '../../components/Reservations/Visits/VisitForm/VisitForm';
import { defaultVisit, VisitFormValues } from '../../models/Visits/VisitFormValues';

const { confirm } = Modal;

// Props
interface VisitFormProps {
  isEditing?: boolean;
  visit?: Visit;
}

// Hook
export const useVisitForm = <T extends object>({ isEditing, visit }: VisitFormProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ reservations }) => reservations?.updating ?? false);
  const lastEventId = useAppSelector(({ reservations }) => reservations?.lastEventId);
  const error = useAppSelector(({ reservations }) => reservations?.error ?? false);

  // Confirmation Modals
  const confirmResendEmail = useCallback(() => {
    return new Promise<boolean>((res) => {
      confirm({
        title: intl.formatMessage({ id: 'visits.notifications.resendConfirmationMail' }),
        icon: <UserDeleteOutlined />,
        content: intl.formatMessage({ id: 'visits.notifications.resendConfirmationMailSub' }),
        okText: intl.formatMessage({ id: 'app.yes' }),
        cancelText: intl.formatMessage({ id: 'app.no' }),
        okType: 'danger',
        onOk: () => res(true),
        onCancel: () => res(false),
      });
    });
  }, [intl]);

  // Submit Handling
  const onSubmit = useCallback(
    async (values: unknown) => {
      if (isEditing) {
        dispatch(
          updateVisit({
            visit: values as Visit,
            sendEmail: await confirmResendEmail(),
          })
        );
      } else {
        dispatch(createVisit(values as Visit));
      }
    },
    [confirmResendEmail, dispatch, isEditing]
  );

  // Form
  const CreateVisitForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <VisitForm
        form={form}
        initialValues={(visit as VisitFormValues) || defaultVisit}
        onSubmit={onSubmit}
        updating={updating}
      />
    ),
    [onSubmit, updating, visit]
  );

  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'Visits',
        Form: CreateVisitForm,
        labels: {
          createButton: <Translated id="reservations.form.create" />,
          drawerForm: <Translated id={isEditing ? 'reservations.form.edit' : 'reservations.form.create'} />,
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateVisitForm, isEditing]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      lastEventId,
      error,
    }),
    [formOptions, updating, lastEventId, error]
  );
};
