import React, { useCallback, useEffect, useMemo } from 'react';
import { Checkbox, Col, Divider, Form, Input, Row, Skeleton, Typography } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

// Components
import { useNavigate } from 'react-router';
import { Translated } from '../../components/UI/Core';
import { Container, Flex } from '../../components/UI/Base';
import { Widget } from '../../components/UI/Widget/Widget';
import { ColorButton } from '../../components/UI/Button/ColorButton';
import { ApiEndpoints } from '../../data/ApiEndpoints';

// Models
// Hooks
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { useData } from '../../hooks/App/useData';
import { Identifier } from '../../models/Identifier';
import { createVisitor } from '../../store/Reservations/Reservations.redux';

const { Title } = Typography;

// Styled UI component
const StyledWidget = styled(Widget)`
  margin: 16px 0 0;

  & .ant-card-body {
    padding: 12px 16px 0;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledFormItem = styled(Form.Item)`
  font-weight: 600;
`;

const StyledInput = styled(Input)`
  padding: 12px;
  box-shadow: 0 8px 6px -11px black;
`;

const StyledContainer = styled('div')`
  width: 100%;
  @media screen and (min-width: 992px) {
    padding-left: 12%;
    padding-right: 12%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  text-align: left;
  font-weight: 600;
`;

export const NewVisitorPage = () => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    data: identifiers,
    fetch: fetchIdentifiers,
    pending,
  } = useData(ApiEndpoints.reservations.identifierTypes, null);
  const { data: visitor, fetch: fetchVisitor } = useData(ApiEndpoints.reservations.detail, null);
  const userEmail = useAppSelector(({ account }) => account?.userEmail);

  useEffect(() => {
    fetchVisitor();
    fetchIdentifiers();
  }, [fetchIdentifiers, fetchVisitor]);

  useEffect(() => {
    // Navigates to reservations page if visitor exists
    if (visitor) {
      navigate(`/reservations`);
    }
  }, [visitor, navigate]);

  const identifiersData = useMemo(
    () =>
      identifiers?.map((identifier) => ({
        id: identifier.Id,
        name: identifier.Name,
        valueLabel: identifier.ValueLabel,
        displayLabel: identifier.DisplayLabel,
        defaultForCheckIn: identifier.DefaultForCheckIn,
        requiredInParkingReservation: identifier.RequiredInParkingReservation,
      })) ?? [],
    [identifiers]
  );

  const onSubmit = useCallback(async () => {
    try {
      const formValues = await form.validateFields();
      dispatch(
        createVisitor({
          FullName: '',
          Id: '',
          FirstName: formValues.FirstName,
          Prefix: formValues.Prefix,
          LastName: formValues.LastName,
          Email: formValues.Email,
          CompanyName: formValues.CompanyName,
          PhoneNumber: formValues.PhoneNumber,
          Identifiers: formValues.Identifiers?.filter((x: Identifier) => x.Value).map((x: Identifier) => ({
            Value: x.Value,
            Name: x.Name ?? '',
            Active: true,
            IdentifierTypeId: x.Id,
          })),
          Visits: [],
          AccessProfiles: [],
          ActionStatuses: [],
        })
      );
    } catch (e) {
      return false;
    }
    return navigate(`/reservations`);
  }, [dispatch, form, navigate]);

  const NewVisitorForm = useCallback(
    () => (
      <StyledForm layout="vertical" form={form} initialValues={{ Email: userEmail }}>
        {/* Visit Details */}
        <Col span={24}>
          <h1 style={{ marginBottom: 15, marginTop: 30 }}>
            <Translated id="reservations.newVisitor.welcome" />
          </h1>
          <Divider />
        </Col>
        <Row style={{ flexDirection: 'row' }}>
          <Title level={5} style={{ marginBottom: 15, marginLeft: 15 }}>
            <Translated id="reservations.newVisitor.fillInDetails" />
          </Title>
        </Row>

        {/* Guest Details */}
        <Col span={24}>
          <h1 style={{ marginBottom: 15, marginTop: 10 }}>
            <Translated id="visitors.form.details" />
          </h1>
          <Divider />
        </Col>

        {/* First Name & Prefix */}
        <Row style={{ flexDirection: 'row' }}>
          <Col xs={24} sm={24} md={9}>
            <StyledFormItem
              name={['FirstName']}
              label={
                <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                  <Translated id="visitors.firstName" />
                </p>
              }
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'visitors.form.warnings.firstName' }),
                },
              ]}
            >
              <StyledInput placeholder="Hubert" />
            </StyledFormItem>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <StyledFormItem
              name={['Prefix']}
              label={
                <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                  <Translated id="visitors.prefix" />
                </p>
              }
            >
              <StyledInput placeholder="van der" />
            </StyledFormItem>
          </Col>
          <Col xs={24} sm={24} md={9}>
            <StyledFormItem
              name={['LastName']}
              label={
                <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                  <Translated id="visitors.lastName" />
                </p>
              }
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'visitors.form.warnings.lastName' }),
                },
              ]}
            >
              <StyledInput placeholder="Renner" />
            </StyledFormItem>
          </Col>
        </Row>

        {/* Email */}
        <Row style={{ flexDirection: 'row' }}>
          <Col xs={24} sm={24} md={8}>
            <StyledFormItem
              name={['Email']}
              label={
                <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                  <Translated id="visitors.email" />
                </p>
              }
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'visitors.form.warnings.email' }),
                },
              ]}
            >
              <StyledInput type="email" placeholder="h.renner@company.com" />
            </StyledFormItem>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <StyledFormItem
              name={['PhoneNumber']}
              label={
                <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                  <Translated id="visitors.phone" />
                </p>
              }
            >
              <StyledInput type="tel" placeholder="+31 6 3208 3380" />
            </StyledFormItem>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <StyledFormItem
              name={['CompanyName']}
              label={
                <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                  <Translated id="visitors.companyName" />
                </p>
              }
            >
              <StyledInput placeholder="Bluefield Smart Access" />
            </StyledFormItem>
          </Col>
        </Row>

        {/* Identifiers */}
        {!!identifiersData.length && (
          <Col span={24}>
            <h1 style={{ marginBottom: 15, marginTop: 10 }}>Identifiers</h1>
            <Divider />
          </Col>
        )}

        {identifiersData.map((data, index) => (
          <Row key={data.id} style={{ flexDirection: 'row' }}>
            <Col xs={24} sm={24} md={12}>
              <StyledFormItem
                name={['Identifiers', index, 'Value']}
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    {isEmpty(data.valueLabel) ? 'Value' : data.valueLabel}
                  </p>
                }
                initialValue=""
                dependencies={[['Identifiers', index, 'Name']]}
                rules={[
                  {
                    required: data.requiredInParkingReservation,
                    message: intl.formatMessage(
                      { id: 'identifiers.form.warnings.value' },
                      { label: isEmpty(data.valueLabel) ? 'Value' : data.valueLabel.toLowerCase() }
                    ),
                    validator: async (_, value) => {
                      const name = form.getFieldValue(['Identifiers', index, 'Name']);
                      if (!value && (!!name || data.requiredInParkingReservation)) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage(
                              { id: 'identifiers.form.warnings.value' },
                              { label: isEmpty(data.valueLabel) ? 'Value' : data.valueLabel.toLowerCase() }
                            )
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <StyledInput placeholder={isEmpty(data.valueLabel) ? 'Value' : data.valueLabel} />
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <StyledFormItem
                name={['Identifiers', index, 'Name']}
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    {isEmpty(data.displayLabel) ? intl.formatMessage({ id: 'visits.form.name' }) : data.displayLabel}
                  </p>
                }
                initialValue=""
                dependencies={[['Identifiers', index, 'Value']]}
                rules={[
                  {
                    required: data.requiredInParkingReservation,
                    message: intl.formatMessage(
                      { id: 'identifiers.form.warnings.name' },
                      {
                        label: isEmpty(data.displayLabel)
                          ? intl.formatMessage({ id: 'visits.form.name' })
                          : data.displayLabel.toLowerCase(),
                      }
                    ),
                    validator: async (_, name) => {
                      const value = form.getFieldValue(['Identifiers', index, 'Value']);
                      if (!name && (!!value || data.requiredInParkingReservation)) {
                        return Promise.reject(
                          new Error(
                            intl.formatMessage(
                              { id: 'identifiers.form.warnings.name' },
                              {
                                label: isEmpty(data.displayLabel)
                                  ? intl.formatMessage({ id: 'visits.form.name' })
                                  : data.displayLabel.toLowerCase(),
                              }
                            )
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <StyledInput
                  placeholder={
                    isEmpty(data.displayLabel) ? intl.formatMessage({ id: 'visits.form.name' }) : data.displayLabel
                  }
                />
              </StyledFormItem>
            </Col>
            <StyledFormItem hidden name={['Identifiers', index, 'Id']} initialValue={data.id}>
              <Input />
            </StyledFormItem>
          </Row>
        ))}

        {/* Agree to process */}
        <Row style={{ flexDirection: 'row', marginTop: 20 }}>
          <Col span={24}>
            <StyledFormItem
              name="agreeToProcess"
              valuePropName="checked"
              rules={[
                {
                  validator: async (_, checked) => {
                    if (!checked) {
                      throw new Error(intl.formatMessage({ id: 'reservations.form.warnings.agreeToProcess' }));
                    }
                    return checked;
                  },
                },
              ]}
            >
              <StyledCheckbox>
                <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                  <Translated id="reservations.agreeToProcessLabel" />
                </p>
              </StyledCheckbox>
            </StyledFormItem>
          </Col>
        </Row>
      </StyledForm>
    ),
    [form, identifiersData, intl, userEmail]
  );

  if (pending || !userEmail) {
    return <Skeleton paragraph={{ rows: 7 }} />;
  }

  return (
    <Container style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <StyledWidget>
        <StyledContainer>
          <NewVisitorForm />
        </StyledContainer>
        <Divider />
        <Flex key="container">
          <ColorButton color="green" size="large" block xl onClick={() => onSubmit()}>
            <Translated id="app.save" />
          </ColorButton>
        </Flex>
      </StyledWidget>
    </Container>
  );
};
