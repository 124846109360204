import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Translated } from '../Core';
import { ButtonSkeleton } from '../Skeleton/ButtonSkeleton';

// Styled
const StyledIcon = styled.span`
  font-size: 24px;
`;

// Props
interface LabelWithIconProps {
  translationId: string;
  value: string | number | ReactNode;
  pending?: boolean;
  icon: ReactNode;
}

// Component
export const LabelWithIcon = ({ translationId, value, pending, icon }: LabelWithIconProps) => (
  <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
    <div className="gx-mr-3">
      <StyledIcon className="gx-label-icon">{icon}</StyledIcon>
    </div>
    <div className="gx-media-body">
      <span className="gx-mb-0 gx-text-grey gx-fs-sm">
        <Translated id={translationId} />
      </span>
      <div className="gx-mb-0">
        <span>{pending ? <ButtonSkeleton width={120} height={10} /> : value}</span>
      </div>
    </div>
  </div>
);

LabelWithIcon.defaultProps = {
  pending: false,
};
