import React from 'react';
import { Col, Row } from 'antd';

// Components
import { Container } from '../../components/UI/Base';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer, ProfileHeader, VisitorIdentifiers } from '../../components/Reservations';
import { VisitsTabs } from '../../components/Reservations/Visits/VisitsTabs/VisitsTabs';
import { useReservationDetail } from '../../hooks/Reservations/useReservationDetail';
import { ReservationsDrawer } from '../../components/Reservations/VisitorReservations/ReservationsDrawer';
import { useReservationsDrawer } from '../../hooks/Reservations/VisitorReservations/useReservationsDrawer';

export const ReservationsPage = () => {
  const {
    updating,
    getProfileHeaderProps,
    getVisitorFormDrawerProps,
    getEditVisitFormDrawerProps,
    getVisitorProps,
    getVisitorVisitsProps,
  } = useReservationDetail();

  const { getReservationsDrawerProps, getCreateVisitFormDrawerProps, setReservationDrawerOpen } =
    useReservationsDrawer();

  return (
    <Spinner spinning={updating}>
      <ProfileHeader {...getProfileHeaderProps()} setReservationDrawerOpen={setReservationDrawerOpen} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={14} lg={14} md={14} sm={24} xs={24}>
            <Col>
              <VisitorIdentifiers {...getVisitorProps()} />
            </Col>
          </Col>

          <Col xl={10} lg={10} md={10} sm={24} xs={24}>
            <Col>
              <VisitsTabs {...getVisitorVisitsProps()} />
            </Col>
          </Col>
        </Row>
      </Container>

      <ReservationsDrawer
        {...getReservationsDrawerProps()}
        getCreateVisitFormDrawerProps={getCreateVisitFormDrawerProps}
      />
      <FormDrawer {...getVisitorFormDrawerProps()} />
      <FormDrawer {...getEditVisitFormDrawerProps()} />
    </Spinner>
  );
};
