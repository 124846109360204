import React from 'react';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useAppSelector } from '../../../hooks/App/useRedux';

// Constants
import { menuItems } from '../../../data/Menu/MenuItems';
import { IdentityRoles } from '../../../constants/Settings/AuthSettings';

export const AppMenu = () => {
  const navigate = useNavigate();

  const pathname = useAppSelector(({ appSettings }) => appSettings?.pathname ?? '/');
  const selectedKey = pathname === '/' ? 'Dashboard' : pathname.substring(1).split('/')[0];

  // TODO: Think of a better way of doing this
  const userRoles = useAppSelector(({ account }) => account?.userRoles ?? []);

  if (!userRoles.includes(IdentityRoles.ApplicationUser)) return <div />;

  return (
    <Menu onClick={(e) => navigate(`/${e.key}`)} selectedKeys={[selectedKey]} mode="horizontal" items={menuItems} />
  );
};

AppMenu.propTypes = {};
