import React from 'react';
import { Avatar, Button } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { Visitor } from '../../models/Visits/Visitor';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Translated } from '../UI/Core';
import { DetailHeader } from '../App/AppLayout/DetailHeader';

// Styled
const StyledSpan = styled.span`
  margin-left: 5px !important;
`;

interface ProfileHeaderProps {
  visitor: Visitor | null;
  pending: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteConfirm: (visitor: Visitor | null) => void;
}

export const ProfileHeader = ({ visitor, pending, setOpen, showDeleteConfirm }: ProfileHeaderProps) => {
  return (
    <DetailHeader>
      <div className="gx-profile-banner-top">
        <div className="gx-profile-banner-top-left">
          <div className="gx-profile-banner-avatar">
            <Avatar
              className="gx-size-90"
              alt="..."
              src="https://as2.ftcdn.net/jpg/02/60/78/83/500_F_260788352_x5sSHM4DGvpjHj9wz8sFltzAPktQwJCj.jpg"
            />
          </div>
          <div className="gx-profile-banner-avatar-info">
            <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
              {pending ? <ButtonSkeleton width={240} height={16} /> : visitor?.FullName}
            </h2>
            <div className="gx-mb-0 gx-fs-lg">
              {pending ? <ButtonSkeleton width={160} height={16} /> : visitor?.CompanyName}
            </div>
          </div>
        </div>
        <div className="gx-profile-banner-top-right">
          <Button ghost type="primary" icon={<EditOutlined />} key="Edit" onClick={() => setOpen(true)}>
            <StyledSpan>
              <Translated id="app.edit" defaultMessage="Edit" />
            </StyledSpan>
          </Button>
          <Button ghost danger icon={<DeleteOutlined />} key="Delete" onClick={() => showDeleteConfirm(visitor)}>
            <StyledSpan>
              <Translated id="app.delete" defaultMessage="Delete" />
            </StyledSpan>
          </Button>
        </div>
      </div>
    </DetailHeader>
  );
};
