import React from 'react';
import { Form, FormInstance, Input } from 'antd';
import styled from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
// Constants
import { isInThePast } from '../../../../constants/Utils/DateTime';

// Models
import { VisitFormValues } from '../../../../models/Visits/VisitFormValues';

// Components
import { Spinner } from '../../../UI/Spinner/Spinner';
import { VisitFormFields } from './VisitFormFields';

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
export interface VisitFormProps<T> {
  form: FormInstance<T>;
  initialValues?: VisitFormValues;
  onSubmit: (values: unknown) => void;
  updating: boolean;
}

// Component
export const VisitForm = <T extends object>({ form, initialValues, onSubmit, updating }: VisitFormProps<T>) => {
  // Render Form
  return (
    <StyledForm
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={initialValues}
      disabled={isInThePast(initialValues?.ValidTo)}
    >
      <Spinner spinning={updating}>
        <Form.Item name="Id" hidden>
          <Input />
        </Form.Item>

        <VisitFormFields form={form} initialValues={initialValues} />
      </Spinner>
    </StyledForm>
  );
};
