import React, { useMemo } from 'react';
import { Translated } from '../UI/Core';
import { Action } from '../../models/Action';
import { StyledTooltip } from '../UI/Tooltip/StyledTooltip';
import { AppColor } from '../../constants/Utils/Colors';
import { ActionStatus } from '../../models/enums/ActionStatus';
import { UserActionStatus } from '../../models/UserActionStatus';

const getActionStatusLabel = (status: number): { label: string; color: AppColor; icon: string } => {
  switch (status) {
    case ActionStatus.DeviceError:
      return { label: 'DeviceError', color: 'red', icon: 'icon-exclamation' };
    case ActionStatus.GeneralError:
      return { label: 'GeneralError', color: 'orange', icon: 'icon-exclamation' };
    case ActionStatus.Default:
      return { label: 'Not Active', color: 'gray', icon: 'icon-setting' };
    case ActionStatus.Active:
      return { label: 'Active', color: 'green', icon: 'icon-check-circle-o' };
    case ActionStatus.UpdateRequired:
      return { label: 'UpdateRequired', color: 'orange', icon: 'icon-alert' };
    case ActionStatus.Expired:
      return { label: 'Expired', color: 'red', icon: 'icon-close-circle' };
    default:
      return { label: 'Not Active', color: 'gray', icon: 'icon-setting' };
  }
};

interface ActionIconProps {
  statuses: UserActionStatus[];
  action: Action;
}

export const ActionIcon = ({ statuses, action }: ActionIconProps) => {
  const currentStatus = useMemo(() => {
    return statuses.find((x) => x.ActionId === action.Id);
  }, [statuses, action]);

  const message = useMemo(() => {
    if (currentStatus?.Message) {
      return `${getActionStatusLabel(currentStatus?.Status ?? 0).label}:${currentStatus?.Message}`;
    }
    return `${getActionStatusLabel(currentStatus?.Status ?? 0).label}`;
  }, [currentStatus]);

  return (
    <div key={action.Id} className="gx-mr-3" style={{ marginTop: 15 }}>
      <StyledTooltip title={message} placement="bottomRight">
        <i
          className={`icon ${
            currentStatus?.Status != null ? getActionStatusLabel(currentStatus.Status).icon : 'icon icon-setting'
          } gx-fs-xxl`}
          style={{ color: getActionStatusLabel(currentStatus?.Status ?? 0).color }}
        />
      </StyledTooltip>
    </div>
  );
};
