/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { Card, Col, Drawer, DrawerProps, Empty, Row, Skeleton } from 'antd';
import styled from 'styled-components';

// Models
import { CarFilled, ForwardFilled, ThunderboltFilled } from '@ant-design/icons';
import moment from 'moment';

// Components
import { Translated } from '../../UI/Core';
import { Visit } from '../../../models/Visits/Visit';
import { VisitType } from '../../../models/enums/VisitType';
import { Flex } from '../../UI/Base';
import { TenantSettings } from '../../../models/TenantSettings';
import { FormDrawer } from '../../UI/Drawer/FormDrawer';
import { FormOptions } from '../../../types/Table';
import { Spinner } from '../../UI/Spinner/Spinner';

// Styled
const StyledDrawer = styled(Drawer)`
  & .ant-drawer-content-wrapper {
    max-width: 100%;
    width: 736px !important;

    @media (min-width: 800px) {
      width: 378px !important;
    }
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 18rem;
  height: auto;
  aspect-ratio: 2 / 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;

  & .ant-card-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

// Props
export interface FormDrawerProps<T extends object> extends FormOptions<T> {
  updating: boolean;
  open: boolean;
  size: DrawerProps['size'];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ReservationsDrawerProps {
  reservationSettings: TenantSettings | null;
  loading: boolean;
  updating: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setVisit: React.Dispatch<React.SetStateAction<Visit | undefined>>;
  getCreateVisitFormDrawerProps: () => FormDrawerProps<Visit>;
}

// Component
export const ReservationsDrawer = ({
  reservationSettings,
  loading,
  updating,
  open,
  setOpen,
  setVisit,
  getCreateVisitFormDrawerProps,
}: ReservationsDrawerProps) => {
  // Render Methods
  const renderLoading = () => {
    return (
      <Row>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <Skeleton active />
        </Col>
      </Row>
    );
  };

  const renderEmpty = () => {
    return (
      <Row>
        <Col xs={24}>
          <Empty
            description={
              <span>
                <Translated id="reservations.form.empty" />
              </span>
            }
          />
        </Col>
      </Row>
    );
  };

  const RenderForm = useCallback(() => {
    const validFrom = moment().isAfter(moment().hour(17).minutes(0).seconds(0).local())
      ? moment()
      : moment().hour(9).minutes(0).seconds(0).local();

    const validTo = moment().isAfter(moment().hour(17).minutes(0).seconds(0).local())
      ? moment().add(4, 'hours')
      : moment().hour(17).minutes(0).seconds(0).local();

    return (
      <StyledDrawer title={<Translated id="reservations.form.create" />} open={open} onClose={() => setOpen(false)}>
        <Spinner spinning={updating}>
          <Flex
            style={{ marginTop: 25 }}
            direction="column"
            alignContent="center"
            justifyContent="space-evenly"
            flexWrap
          >
            {!reservationSettings?.RegularParkingReservationsEnabled &&
              !reservationSettings?.ElectricParkingReservationsEnabled &&
              !reservationSettings?.FastElectricParkingReservationsEnabled && (
                <Empty
                  description={
                    <span>
                      <Translated id="reservations.form.empty" />
                    </span>
                  }
                />
              )}
            {reservationSettings?.RegularParkingReservationsEnabled && (
              <StyledCard
                hoverable
                key="Regular"
                onClick={() => {
                  setVisit({
                    Description: 'Regular parking',
                    Guests: [],
                    Hosts: [],
                    Id: '',
                    Title: `Parking Reservation - ${validFrom?.format('L')}`,
                    ValidFrom: validFrom.format(),
                    ValidTo: validTo.format(),
                    VisitType: VisitType.Default,
                  });
                  getCreateVisitFormDrawerProps().setOpen(true);
                }}
              >
                <CarFilled style={{ fontSize: '2rem' }} />
                <span>Regular parking</span>
              </StyledCard>
            )}
            {reservationSettings?.ElectricParkingReservationsEnabled && (
              <StyledCard
                hoverable
                key="Electric"
                onClick={() => {
                  setVisit({
                    Description: 'Electric charging',
                    Guests: [],
                    Hosts: [],
                    Id: '',
                    Title: `Parking Reservation - ${validFrom?.format('L')}`,
                    ValidFrom: validFrom.format(),
                    ValidTo: validTo.format(),
                    VisitType: VisitType.Default,
                  });
                  getCreateVisitFormDrawerProps().setOpen(true);
                }}
              >
                <ThunderboltFilled style={{ fontSize: '2rem' }} />
                <span>Electric parking</span>
              </StyledCard>
            )}
            {reservationSettings?.FastElectricParkingReservationsEnabled && (
              <StyledCard
                hoverable
                key="Fast"
                onClick={() => {
                  setVisit({
                    Description: 'Fast charging',
                    Guests: [],
                    Hosts: [],
                    Id: '',
                    Title: `Parking Reservation - ${validFrom?.format('L')}`,
                    ValidFrom: validFrom.format(),
                    ValidTo: validTo.format(),
                    VisitType: VisitType.Default,
                  });
                  getCreateVisitFormDrawerProps().setOpen(true);
                }}
              >
                <ForwardFilled style={{ fontSize: '2rem' }} />
                <span>Fast electric parking</span>
              </StyledCard>
            )}
          </Flex>
        </Spinner>
        <FormDrawer {...getCreateVisitFormDrawerProps()} />
      </StyledDrawer>
    );
  }, [
    open,
    updating,
    reservationSettings?.RegularParkingReservationsEnabled,
    reservationSettings?.ElectricParkingReservationsEnabled,
    reservationSettings?.FastElectricParkingReservationsEnabled,
    getCreateVisitFormDrawerProps,
    setOpen,
    setVisit,
  ]);

  if (loading) return renderLoading();

  if (!reservationSettings) return renderEmpty();

  // Component Render
  return <RenderForm />;
};
