import React from 'react';
import { Progress, ProgressProps, Space, Tooltip } from 'antd';

// Models
import { ProgressTooltipProps } from '../../../models/UI/ProgressTooltipProps';
import { AppColors } from '../../../constants/Utils/Colors';

// Props
interface DashboardProgressProps extends ProgressProps, ProgressTooltipProps {
  value: string;
  label: string;
  small?: boolean;
}

// Component
export const DashboardProgress = ({
  value,
  label,
  small,
  tooltip,
  percent,
  success,
  strokeColor,
}: DashboardProgressProps) => {
  const getProgressLabel = () => (
    <Space direction="vertical">
      <span style={{ fontSize: small ? '0.85em' : '1.2em' }}>
        <strong>{value}</strong>
      </span>
      <span style={{ fontSize: '0.7em' }}>{label}</span>
    </Space>
  );

  return (
    <Tooltip title={tooltip.content} placement={tooltip.placement}>
      <Progress
        percent={percent}
        success={success}
        strokeColor={strokeColor}
        format={() => getProgressLabel()}
        strokeWidth={8}
        width={120}
        status="normal"
        type="circle"
        trailColor={
          // Fix for bug with AntDesign Progress when
          // success > 0 and percent is 100
          // the bar does not fill completelly
          success && percent === 100 ? 'transparent' : `${AppColors.sky}99`
        }
      />
    </Tooltip>
  );
};

DashboardProgress.defaultProps = {
  small: false,
};
