import React from 'react';
import { Space, Tag } from 'antd';
import { getLocalDateTimeString } from '../../../constants/Utils/DateTime';

interface ValidityTagProps {
  validFrom: string;
  validTo: string;
}

export const TimeframeTags = ({ validFrom, validTo }: ValidityTagProps) => (
  <Space.Compact style={{ alignItems: 'center' }}>
    <Tag className="gx-mr-1 gx-mb-0">{getLocalDateTimeString(validFrom)}</Tag>
    <span className="gx-mb-0">➞</span>
    <Tag className="gx-ml-1 gx-mb-0">{getLocalDateTimeString(validTo)}</Tag>
  </Space.Compact>
);
