import React, { useCallback, useMemo } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Types
import { UserDeleteOutlined } from '@ant-design/icons';
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { Visitor } from '../../models/Visits/Visitor';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import { createVisitor, deleteVisitor, updateVisitor } from '../../store/Reservations/Reservations.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';

const { confirm } = Modal;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Props
interface VisitorFormProps {
  isEditing?: boolean;
  visitor?: Visitor | null;
}

// Hook
export const useVisitorForm = <T extends object>({ isEditing, visitor }: VisitorFormProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ reservations }) => reservations?.updating ?? false);
  const error = useAppSelector(({ reservations }) => reservations?.error ?? false);

  // Submit Handling
  const onSubmit = useCallback(
    (values: unknown) => {
      if (isEditing) {
        dispatch(updateVisitor(values as Visitor));
      } else {
        dispatch(createVisitor(values as Visitor));
      }
    },
    [dispatch, isEditing]
  );

  // Components
  const CreateVisitorForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm form={form} layout="vertical" onFinish={onSubmit} initialValues={visitor ?? undefined}>
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="FirstName"
                label={<Translated id="visitors.firstName" />}
                rules={[{ required: true, message: intl.formatMessage({ id: 'visitors.form.warnings.firstName' }) }]}
              >
                <Input placeholder="Hubert" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="Prefix" label={<Translated id="visitors.prefix" />}>
                <Input placeholder="van der" />
              </Form.Item>
              <Form.Item
                name="LastName"
                label={<Translated id="visitors.lastName" />}
                rules={[{ required: true, message: intl.formatMessage({ id: 'visitors.form.warnings.lastName' }) }]}
              >
                <Input placeholder="Renner" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="Email"
                label={<Translated id="visitors.email" />}
                rules={[{ required: true, message: intl.formatMessage({ id: 'visitors.form.warnings.email' }) }]}
              >
                <Input type="email" placeholder="h.renner@company.com" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="PhoneNumber" label={<Translated id="visitors.phone" />}>
                <Input type="tel" placeholder="+31 6 3208 3380" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="CompanyName" label={<Translated id="visitors.companyName" />}>
                <Input placeholder="Bluefield Smart Access" />
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, visitor, intl]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (entity: Visitor | null) => {
      if (entity) {
        confirm({
          title: intl.formatMessage({
            id: 'visitor.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'visitor.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteVisitor(entity)),
          onCancel: () => null,
        });
      }
    },
    [dispatch, intl]
  );

  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'Visitors',
        Form: CreateVisitorForm,
        labels: {
          createButton: <Translated id="visitors.form.create" />,
          drawerForm: <Translated id={isEditing ? 'visitors.form.edit' : 'visitors.form.create'} />,
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateVisitorForm, isEditing]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
    }),
    [formOptions, updating, error, showDeleteConfirm]
  );
};
