import React, { useCallback, useState } from 'react';
import { Avatar, Collapse, Modal, Typography } from 'antd';
import { AuditOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import { Visit } from '../../../../models/Visits/Visit';
import { Visitor } from '../../../../models/Visits/Visitor';

// Components
import { VisitDetails } from './VisitDetails';
import { VisitSteps } from './VisitSteps';
import { Flex } from '../../../UI/Base';
import { Translated } from '../../../UI/Core';
import { StatusButton } from '../../StatusButton';
import { GuestStatus } from '../../../../models/enums/GuestStatus';
import { ColorButton } from '../../../UI/Button/ColorButton';
import { BaseColors } from '../../../../constants/Utils/Colors';

// Hooks
import { useAppDispatch } from '../../../../hooks/App/useRedux';

// Actions
import { deleteVisit } from '../../../../store/Visits/Visits.redux';

// Components
const { Title } = Typography;
const { Panel } = Collapse;
const { confirm } = Modal;

// Styled
const StyledCollapse = styled(Collapse)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: none;
  & > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    background-color: ${BaseColors.white};
  }
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 24px;
  }
`;

// Props
interface VisitsTabContentProps {
  visit: Visit;
  visitor: Visitor;
  showVisitDetails: (visit: Visit) => void;
}

// Component
export const VisitsTabContent = ({ visit, visitor, showVisitDetails }: VisitsTabContentProps) => {
  const visitStatus = visit.Guests.find((x) => x.Id === visitor.Id)?.Status ?? 0;
  const guest = visit.Guests.find((x) => x.Id === visitor.Id);
  const visitEditable = !visit.Guests.some(
    (x) => x.Status === GuestStatus.CheckedIn || x.Status === GuestStatus.CheckedOut
  );

  // Intl
  const intl = useIntl();

  // Component Status
  const [activeKey, setActiveKey] = useState<string | Array<string>>(
    visitStatus < GuestStatus.CheckedIn ? 'details' : 'status'
  );

  const dispatch = useAppDispatch();

  const showDeleteConfirm = useCallback(
    (entity: Visit | null) => {
      if (entity) {
        confirm({
          title: intl.formatMessage({
            id: 'visits.confirm.delete',
          }),
          icon: <DeleteOutlined />,
          content: intl.formatMessage({
            id: 'visits.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteVisit(entity)),
          onCancel: () => null,
        });
      }
    },
    [dispatch, intl]
  );

  // Details Panel
  const detailsHeader = (
    <Flex alignItems="center">
      <Avatar className="avatar-border-color" shape="square" icon={<AuditOutlined />} style={{ marginRight: 8 }} />
      <Title level={5} className="gx-mb-0" style={{ fontWeight: 500 }}>
        <Translated id="visits.form.details" />
      </Title>
    </Flex>
  );
  const detailsButton = (
    <div>
      <ColorButton
        disabled={!visitEditable}
        color="main"
        icon={<EditOutlined />}
        className="gx-mb-0"
        onClick={(e) => {
          e.stopPropagation();
          showVisitDetails(visit);
        }}
      >
        <span className="gx-ml-1">
          <Translated id="app.edit" />
        </span>
      </ColorButton>
      <ColorButton
        disabled={!visitEditable}
        color="red"
        className="gx-mb-0"
        icon={<DeleteOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          showDeleteConfirm(visit);
        }}
      >
        <span className="gx-ml-1">
          <Translated id="app.delete" defaultMessage="Delete" />
        </span>
      </ColorButton>
    </div>
  );

  // Status Panel
  const statusHeader = (
    <Flex alignItems="center">
      <Avatar className="avatar-border-color" shape="square" icon={<InfoCircleOutlined />} style={{ marginRight: 8 }} />
      <Title level={5} className="gx-mb-0" style={{ fontWeight: 500 }}>
        <Translated id="visits.info.status" />
      </Title>
    </Flex>
  );
  const statusButton = <StatusButton visit={visit} visitor={visitor} onOk={() => setActiveKey('status')} />;

  // Render Content
  return (
    <StyledCollapse activeKey={activeKey} onChange={setActiveKey}>
      <Panel key="details" header={detailsHeader} extra={detailsButton} showArrow={false}>
        <VisitDetails visit={visit} />
      </Panel>
      <Panel key="status" header={statusHeader} extra={statusButton} showArrow={false}>
        <VisitSteps visit={visit} guest={guest} />
      </Panel>
    </StyledCollapse>
  );
};
