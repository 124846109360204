import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

// Models
// Hooks
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';
import { useAppSelector } from '../../App/useRedux';
import { useVisitForm } from '../useVisitForm';
import { useFormDrawer } from '../../Drawer/useFormDrawer';
import { Visit } from '../../../models/Visits/Visit';

export const useReservationsDrawer = <T extends object>() => {
  // State
  const [visit, setVisit] = useState<Visit>();

  // Drawer State
  const [open, setOpen] = useState(false);

  // Redux State
  const error = useAppSelector(({ reservations }) => reservations?.error ?? false);
  const updating = useAppSelector(({ reservations }) => reservations?.updating ?? false);
  const prevUpdating = usePrevious(updating);

  // Data
  const {
    data: reservationSettings,
    fetch: fetchReservationSettings,
    pending: pendingReservationSettings,
  } = useData(ApiEndpoints.reservations.settings, null);
  const {
    data: visitor,
    fetch: fetchVisitor,
    pending: pendingVisitor,
  } = useData(ApiEndpoints.reservations.detail, null);

  // Form Hooks
  const {
    formOptions: createVisitFormOptions,
    updating: createVisitUpdating,
    error: createVisitError,
  } = useVisitForm<T>({ isEditing: false, visit });

  // Component State
  const loading = pendingReservationSettings || pendingVisitor || !visitor || !reservationSettings;

  // Effects
  useEffect(() => {
    fetchReservationSettings();
    fetchVisitor();
  }, [fetchReservationSettings, fetchVisitor]);

  useEffect(() => {
    // Fetch after updating
    if (visitor && prevUpdating === true && !updating && !error) {
      setOpen(false);
      fetchVisitor();
    }
  }, [prevUpdating, updating, error, fetchVisitor, visitor]);

  // Form Drawer(s)
  const { getFormDrawerProps: getCreateVisitFormDrawerProps } = useFormDrawer<T>({
    formOptions: createVisitFormOptions,
    updating: createVisitUpdating,
    error: createVisitError,
  });

  const getReservationsDrawerProps = useCallback(
    () => ({
      reservationSettings,
      loading,
      updating,
      open,
      setOpen,
      setVisit,
    }),
    [reservationSettings, loading, updating, open]
  );

  // Return Hook
  return useMemo(
    () => ({
      getReservationsDrawerProps,
      getCreateVisitFormDrawerProps,
      setReservationDrawerOpen: setOpen,
    }),
    [getReservationsDrawerProps, getCreateVisitFormDrawerProps]
  );
};
