// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  visitCreated,
  visitDeleted,
  visitNotCreated,
  visitNotDeleted,
  visitNotUpdated,
  visitorCreated,
  visitorDeleted,
  visitorNotCreated,
  visitorNotDeleted,
  visitorNotUpdated,
  visitorUpdated,
  visitUpdated,
} from './Reservations.redux';

// Events
const SignalREvents = {
  visitCreated: `VisitCreatedIntegrationEvent`,
  visitUpdated: `VisitUpdatedIntegrationEvent`,
  visitDeleted: `VisitDeletedIntegrationEvent`,
  visitorCreated: `VisitorCreatedIntegrationEvent`,
  visitorUpdated: `VisitorUpdatedIntegrationEvent`,
  visitorDeleted: `VisitorDeletedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  visitNotCreated: `VisitNotCreatedIntegrationException`,
  visitNotUpdated: `VisitNotUpdatedIntegrationException`,
  visitNotDeleted: `VisitNotDeletedIntegrationException`,
  visitorNotCreated: `VisitorNotCreatedIntegrationException`,
  visitorNotDeleted: `VisitorNotDeletedIntegrationException`,
  visitorStatusNotUpdated: `VisitorStatusNotUpdatedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create Visit
  hubConnection.on(SignalREvents.visitCreated, (msg) => {
    store.dispatch(visitCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitNotCreated, (msg) => {
    store.dispatch(visitNotCreated({ history, msg }));
  });

  // Update Visit
  hubConnection.on(SignalREvents.visitUpdated, (msg) => {
    store.dispatch(visitUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitNotUpdated, (msg) => {
    store.dispatch(visitNotUpdated({ history, msg }));
  });

  // Delete Visit
  hubConnection.on(SignalREvents.visitDeleted, (msg) => {
    store.dispatch(visitDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitNotDeleted, (msg) => {
    store.dispatch(visitNotDeleted({ history, msg }));
  });

  // Create Visitor
  hubConnection.on(SignalREvents.visitorCreated, (msg) => {
    store.dispatch(visitorCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitorNotCreated, (msg) => {
    store.dispatch(visitorNotCreated({ history, msg }));
  });

  // Update Visitor
  hubConnection.on(SignalREvents.visitorUpdated, (msg) => {
    store.dispatch(visitorUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitorStatusNotUpdated, (msg) => {
    store.dispatch(visitorNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.visitorDeleted, (msg) => {
    store.dispatch(visitorDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitorNotDeleted, (msg) => {
    store.dispatch(visitorNotDeleted({ history, msg }));
  });
};
