import React from 'react';
import { MenuProps } from 'antd';

// Constants
import { AppMenuItem } from '../../components/App/AppMenu/AppMenuItem';

export const menuItems: MenuProps['items'] = [
  {
    key: 'Visitors',
    label: <AppMenuItem id="visitors" to="/Visitors" icon="avatar" />,
    className: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
  },
  {
    key: 'Employees',
    label: <AppMenuItem id="employees" to="/Employees" icon="auth-screen" />,
    className: 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve',
  },
];
