import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { usePrevious } from 'react-use';
import { isEmpty } from 'lodash';

// Hooks
import { useData } from '../App/useData';
import { useVisitorForm } from './useVisitorForm';
import { useFormDrawer } from '../Drawer/useFormDrawer';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { Visit } from '../../models/Visits/Visit';
import { useVisitForm } from './useVisitForm';
import { isInThePast } from '../../constants/Utils/DateTime';

// Hook
export const useReservationDetail = <T extends object>() => {
  // Navigation Hook
  const navigate = useNavigate();

  // State
  const [visit, setVisit] = useState<Visit>();

  // Data
  const { data: visitor, fetch, pending } = useData(ApiEndpoints.reservations.detail, null);
  const prevPending = usePrevious(pending);
  const futureVisits = visitor?.Visits.filter((x) => !isInThePast(x.ValidTo));
  const hasVisits = !isEmpty(futureVisits);

  // Form Hooks
  const {
    formOptions: visitorFormOptions,
    updating: visitorUpdating,
    error: visitorError,
    showDeleteConfirm,
  } = useVisitorForm<T>({ isEditing: true, visitor });

  const {
    formOptions: editVisitFormOptions,
    updating: editVisitUpdating,
    error: editVisitError,
  } = useVisitForm<T>({ isEditing: true, visit });

  // Form Drawer(s)
  const { getFormDrawerProps: getVisitorFormDrawerProps } = useFormDrawer<T>({
    formOptions: visitorFormOptions,
    updating: visitorUpdating,
    error: visitorError,
  });

  const { getFormDrawerProps: getEditVisitFormDrawerProps } = useFormDrawer<T>({
    formOptions: editVisitFormOptions,
    updating: editVisitUpdating,
    error: editVisitError,
  });

  // Form Status
  const error = visitorError || editVisitError;
  const updating = visitorUpdating || editVisitUpdating;
  const prevUpdating = usePrevious(updating);

  // Props
  const getProfileHeaderProps = useCallback(
    () => ({
      visitor,
      pending,
      setEditVisitorOpen: getVisitorFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [visitor, pending, getVisitorFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      visitor,
      pending,
    }),
    [visitor, pending]
  );
  const getVisitorProps = useCallback(
    () => ({
      visitor,
      fetchVisitor: fetch,
      updatingVisitor: pending,
      hasVisits,
    }),
    [visitor, fetch, pending, hasVisits]
  );
  const getVisitorVisitsProps = useCallback(
    () => ({
      visitor,
      showVisitDetails: (newVisit: Visit) => {
        setVisit(newVisit);
        getEditVisitFormDrawerProps().setOpen(true);
      },
    }),
    [visitor, getEditVisitFormDrawerProps]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch();
  }, [fetch]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && !pending && !visitor) {
      navigate(`/reservations/new_visitor`);
    }
  }, [prevPending, pending, visitor, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (prevUpdating === true && !updating && !error) {
      fetch();
    }
  }, [prevUpdating, updating, error, fetch]);

  return useMemo(
    () => ({
      updating,
      getProfileHeaderProps,
      getContactCardProps,
      getVisitorFormDrawerProps,
      getEditVisitFormDrawerProps,
      getVisitorProps,
      getVisitorVisitsProps,
    }),
    [
      updating,
      getProfileHeaderProps,
      getContactCardProps,
      getVisitorFormDrawerProps,
      getEditVisitFormDrawerProps,
      getVisitorProps,
      getVisitorVisitsProps,
    ]
  );
};
